import {
  createAppKit,
  useAppKit,
  useAppKitAccount,
  useDisconnect,
} from "@reown/appkit/react";

import { EthersAdapter } from "@reown/appkit-adapter-ethers";
import { mainnet } from "@reown/appkit/networks";

import {useEffect} from "react";

export default function WalletConnection() {
  const projectId = "6a1e06545e8a9b085adc02e47ead35fe";

  const networks = [mainnet];

  const metadata = {
    name: "BOKZ",
    description: "BOKZ",
    url: "https://platform.bokz.io/",
    icons: [
      "https://cdn.prod.website-files.com/673622049ba1ada0146814a2/6736291c527f8f654096e5e7_platform_logo.svg",
    ],
  };

  createAppKit({
    adapters: [new EthersAdapter()],
    networks,
    defaultNetwork: mainnet,
    enableWalletConnect: false,
    metadata,
    projectId,
    features: {
      analytics: false,
      email: false,
      socials: [],
      emailShowWallets: false,
    },
    allWallets: "HIDE",
    featuredWalletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", //metamask
    ],
    excludeWalletIds: [
      "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709", //okx
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", //trust wallet
      "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", //bitget
      "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4",
      "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a",
      "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
      "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
      "15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f",
      "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927",
      "344d0e58b139eb1b6da0c29ea71d52a8eace8b57897c6098cb9b46012665c193",
      "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
      "c286eebc742a537cd1d6818363e9dc53b21759a1e8e5d9b263d0c03ec7703576",
      "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d",
      "18450873727504ae9315a084fa7624b5297d2fe5880f0982979c17345a138277",
      "8837dd9413b1d9b585ee937d27a816590248386d9dbf59f5cd3422dbbb65683e",
      "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
      "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393",
      "c87c562ce7f3a3ff9f4eed5f5a0edbcbd812db5aed4d14c7e6c044d8b6795d84",
      "163d2cf19babf05eb8962e9748f9ebe613ed52ebf9c8107c9a0f104bfcf161b3"
    ],
  });

  const { open } = useAppKit();
  const { address, isConnected } = useAppKitAccount();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (address && isConnected) {
      localStorage.setItem("address", address);
    } else {
      localStorage.removeItem("address");
    }

    localStorage.setItem("isConnected", isConnected);
  }, [address, isConnected])

  return (
    <>
        <button
          className="button"
          onClick={open}
          disabled={isConnected}
        >
          { isConnected ? "Wallet connected" : "Connect wallet" }
        </button>
        { isConnected && <button className="button" onClick={disconnect}>Disconnect</button> }
    </>
  );
}
